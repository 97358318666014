:root{
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 20px;
}

code {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
}

button.action{
  padding: 8px 30px;
  display: block;
  width: 100%;
  outline: none;
  border: 0;
  background-color: #3971F9;
  margin-bottom: 20px;

  cursor: pointer;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  line-height: 1.17648;

  transition: background ease-in-out .3s;
}

button.action-error{
  background: #e74c3c;
}

button.action-loading{
  background: #9b59b6;
}

button.action-success{
  background: #2ecc71;
}

h1{
  text-align: center;
  font-size: 1.813rem;
  font-weight: 300;

  color: #333333;
}

.mb{
  margin-bottom: 20px;
}

@media (prefers-color-scheme: dark) {
  body{
    background-color: #282a36;
  }

  h1{
    color: #ffffff;
  }
}